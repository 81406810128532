import React, { Fragment } from "react";


interface Props {
  selected: number[];
  toggleSelected: (id: number) => void;
  element: any,
  updateElement: (key: string, val: any) => void,
}

interface IOption {
  id: number;
  name: string;
}

const options: IOption[] = [
  { id: 5891, name: "Lithuania" },
  { id: 51, name: "Latvia" },
  { id: 11219, name: "Estonia" },
  { id: 38, name: "Kazakhstan" },
  { id: 1708, name: "Kyrgyzstan" },
  { id: 56, name: "Uzbekistan" },
  { id: 1715, name: "Tajikistan" },
  { id: 42, name: "Turkmenistan" },
  { id: 87053, name: "Belarus" },
  { id: 85, name: "Russia" },
  { id: 251993, name: "Azerbaijan" },
  // { id: 100, name: "Kita" },
  // { id: 0, name: "Visos" },
];

export default function WorkDirectionIn(props: Props) {
  const t = (s: string) => s;

  return (
    <Fragment>
      <p className="font-medium">{t('To')}</p>
      <div className="space-y-1">
        {options.map((opt: IOption) => {
          return (
            <label
              className={"flex gap-2 items-center"}
              key={"work-direction-in-" + opt.id}
            >
              <input
                type={"checkbox"}
                checked={props.selected && props.selected.indexOf(opt.id) >= 0}
                onChange={() => props.toggleSelected(opt.id)}
              />{" "}
              <p>{opt.name}</p>
            </label>
          );
        })}
      </div>
    </Fragment>
  );
}
